// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-coming-soon-tsx": () => import("./../../../src/pages/coming-soon.tsx" /* webpackChunkName: "component---src-pages-coming-soon-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contest-tsx": () => import("./../../../src/pages/contest.tsx" /* webpackChunkName: "component---src-pages-contest-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-done-tsx": () => import("./../../../src/pages/done.tsx" /* webpackChunkName: "component---src-pages-done-tsx" */),
  "component---src-pages-donors-tsx": () => import("./../../../src/pages/donors.tsx" /* webpackChunkName: "component---src-pages-donors-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-practice-editorial-tsx": () => import("./../../../src/pages/practice-editorial.tsx" /* webpackChunkName: "component---src-pages-practice-editorial-tsx" */),
  "component---src-pages-register-official-tsx": () => import("./../../../src/pages/register-official.tsx" /* webpackChunkName: "component---src-pages-register-official-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-register-unofficial-tsx": () => import("./../../../src/pages/register-unofficial.tsx" /* webpackChunkName: "component---src-pages-register-unofficial-tsx" */),
  "component---src-pages-schedule-tsx": () => import("./../../../src/pages/schedule.tsx" /* webpackChunkName: "component---src-pages-schedule-tsx" */),
  "component---src-pages-scope-tsx": () => import("./../../../src/pages/scope.tsx" /* webpackChunkName: "component---src-pages-scope-tsx" */),
  "component---src-pages-sponsors-tsx": () => import("./../../../src/pages/sponsors.tsx" /* webpackChunkName: "component---src-pages-sponsors-tsx" */),
  "component---src-pages-submission-tsx": () => import("./../../../src/pages/submission.tsx" /* webpackChunkName: "component---src-pages-submission-tsx" */),
  "component---src-pages-tasks-tsx": () => import("./../../../src/pages/tasks.tsx" /* webpackChunkName: "component---src-pages-tasks-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-verify-tsx": () => import("./../../../src/pages/verify.tsx" /* webpackChunkName: "component---src-pages-verify-tsx" */)
}

